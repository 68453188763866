.group-customization-module {
  &__all-option,
  &__total-option {
    align-items: center;
    display: flex;

    // NOTE: Tweak the style of the info tooltip directly because the
    // component does not allow customization and has bad defaults.
    .zen-info-tooltip {
      display: flex;
    }
  }
}
