.map-viz {
  &__content {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &__zoom-control {
    left: $space-xs-em;
    position: absolute;
    top: $space-xs-em;
  }
}

.map-viz-wrapper {
  height: 100%;
  width: 100%;
}

.map-popup-container {
  display: flex;
  flex-direction: column;
  pointer-events: none;

  // Apply the original popover styling to just the content since we need to
  // draw the tip separately.
  &__content {
    @extend .zen-popover--empty-container;
  }

  &__tip {
    align-self: center;
    border: 6px solid transparent;
    border-bottom: none;
    border-top: 4px solid $white;
    height: 0;
    width: 0;
  }
}

.map-simple-legend {
  background-color: $white;
  border: $space-xxxs-em solid $gray-5;
  border-radius: $space-xs-em;
  bottom: $space-xs-em;
  max-width: 21em;
  overflow: auto;
  padding: $space-xs-em;
  position: absolute;
  right: $space-xs-em;
  z-index: 2;

  &__item {
    margin-bottom: $space-s-em;
  }

  &__item:last-child {
    margin-bottom: 0px;
  }

  &__row {
    // row element styling
    align-items: center;
    display: flex;
    &--disabled {
      opacity: 0.2;
    }
  }

  &__row-label {
    font-size: 13px;
    // Constrain item contents to a single line.
    line-height: 1;
    max-width: 17em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Create a small shape that shows the color rule being applied.
  &__row-color {
    height: $space-m-em;
    margin-right: $space-xs-em;
    width: $space-m-em;

    &--circle {
      border-radius: $space-m-em;
    }
  }

  &__title {
    border-bottom: $space-xxxs-em solid $gray-5;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: $space-xs-em;
    padding-bottom: $space-xxs-em;
    text-align: center;
  }
}

.map-simple-popup {
  max-width: 450px;
}

.overlay-option-button {
  $button-size: 26px;

  &__map-button {
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 4px;
    height: $button-size;
    width: $button-size;

    // NOTE: Need to change the default icon color that is used by the
    // InfoTooltip. Also need to increase size of tooltip so that hover area is
    // the entire button not just the icon.
    .zen-info-tooltip {
      align-items: center;
      color: $slate;
      display: flex;
      height: 100%;
      justify-content: center;
      margin-left: 0;
      width: 100%;
    }

    &:hover {
      background-color: $gray-light-hover;
    }

    &:active {
      background-color: $gray-light-active;
    }
  }
}

.background-layer-button {
  &__map-button {
    position: absolute;
    right: $space-xs-em;
    top: 50px;
  }

  &__title {
    font-weight: bold;
  }

  &__input-wrapper {
    display: flex;
  }

  &__item {
    align-items: center;
    display: flex;
  }

  &__item-label {
    cursor: pointer;
    margin-bottom: 0;
  }
}

// TODO: Create a component which can be used to wrap multiple controls
// in a box. Use it for this and the label settings
.map-admin-boundary-settings {
  border: 1px solid $gray;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 50% 50%;
}

// TODO: Not sure if this belongs in this file
.map-label-settings {
  border: 1px solid $gray;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

  &__font-settings {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 72px 72px 72px;
  }
}

.map-multi-label-settings {
  align-items: baseline;
  display: flex;

  &__title {
    flex: 1;
  }

  &__controls {
    border-bottom: 1px solid $gray;
    flex: 3;

    // Overriding dropdown css
    .zen-dropdown {
      margin-bottom: $space-xs-px;
      width: 100%;
    }

    // Overriding dropdown css
    .zen-dropdown-button {
      width: 100%;
    }
  }
}

.map-multi-label-color-picker-row {
  align-items: center;
  display: flex;
  margin-bottom: $space-xs-px;

  &__label {
    padding-right: $space-xs-px;
  }

  // NOTE: Need to override color block CSS to properly vertically align
  // caret
  .color-control__color-block {
    line-height: 33px;
  }
}
